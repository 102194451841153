<template>
  <modal
    :modalId="`modalCreateOrEdit`"
    :modalTitle="title"
    :load="false"
    :typeLg="`modal_md`"
    @closeModal="closeModal"
  >
    <ValidationObserver ref="formBanner" class="row" tag="form">
      <div class="row">
        <ValidationProvider
          tag="div"
          class="col-md-12 mb-1 form-group form-outline"
          v-slot="{ errors, ariaMsg, classes }"
          name="Título"
          :rules="{
            required: true,
            max: 50
          }"
        >
          <label class="mb-1 label_default" :class="classes">
            Título
          </label>
          <input
            class="form-control input_default"
            :class="classes"
            v-model="obj.title"
            maxlength="50"
          />
          <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
        </ValidationProvider>
        <ValidationProvider
          tag="div"
          class="col-md-6 mb-1 form-group form-outline"
          v-slot="{ errors, ariaMsg, classes }"
          rules="required"
          name="Data de Início"
        >
          <label class="mb-1 label_default" :class="classes"
            >Data de Início</label
          >
          <div class="input-group">
            <input
              type="date"
              class="form-control input_default"
              :class="classes"
              v-model="obj.dateInitial"
            />
          </div>
          <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
        </ValidationProvider>
        <ValidationProvider
          tag="div"
          class="col-md-6 mb-1 form-group form-outline"
          v-slot="{ errors, ariaMsg, classes }"
          rules="required"
          name="Data Fim"
        >
          <label class="mb-1 label_default" :class="classes">Data Fim</label>
          <div class="input-group">
            <input
              type="date"
              class="form-control input_default"
              :class="classes"
              v-model="obj.dateFinale"
            />
          </div>
          <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
        </ValidationProvider>
        <br>
        <br>
        <ValidationProvider>
          <div class="col-md-6 mb-3 banner-type">
            <div class="form-check">
              <input
                :checked="bannerModal"
                type="checkbox"
                class="form-check-input"
                id="bannerModal"
                name="bannerModal"
                value="bannerModal"
                :disabled="disableCheckbox"
                @change="changeLabelImageFileBannerModal"
              />
              <label class="form-check-label" for="bannerModal">Modal</label>
            </div>
          </div>
        </ValidationProvider>
        <ValidationProvider>
          <div class="col-md-6 mb-3 banner-type banner-label">
            <div class="form-check">
              <input
                :checked="bannerDashboard"
                type="checkbox"
                class="form-check-input"
                id="bannerDashboard"
                name="bannerDashboard"
                value="bannerDashboard"
                :disabled="disableCheckbox"
                @change="changeLabelImageFileBannerDashboard"
              />
              <label class="form-check-label" for="bannerDashboard">Dashboard</label>
            </div>
          </div>
        </ValidationProvider>
        <ValidationProvider
          tag="div"
          class="col-md-12 mb-1 form-group form-outline"
          v-slot="{ errors, ariaMsg, classes, validate }"
          :rules="rules"
          name="Arquivo"
          ref="inputImg"
        >
          <div :class="classes" class="form-control custom-file">
            <input
              type="file"
              :class="classes"
              class="custom-file-input"
              accept="image/*"
              @change="files"
              id="customFile"
            />
            <label
              class="custom-file-label d-flex align-items-center"
              data-browse="Selecionar Arquivo"
              for="customFile"
              >{{ fileName }}</label
            >
          </div>
          <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
        </ValidationProvider>
        <!-- <div class="col-md-12">
          <label class="label_default info">Imagem 790 x 85 pixels</label>
        </div> -->
        <div id="label-image" class="col-md-12"> 
          <label class="label_default info"
            >{{ labelImage }}</label
          >
        </div>
        <ValidationProvider
          tag="div"
          class="col-md-12 mb-1 form-group form-outline"
          v-slot="{ errors, ariaMsg, classes }"
          name="Link"
          :rules="{
            regex: getRegex
          }"
        >
          <label class="mb-1 label_default" :class="classes">
            Url Link
          </label>
          <input
            class="form-control input_default"
            :class="classes"
            v-model="obj.link"
          />
          <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
        </ValidationProvider>
        <ValidationProvider>
          <div class="col-md-4 mb-3">
            <div class="form-check">
              <input
                :checked="checkFranchise"
                type="checkbox"
                class="form-check-input"
                id="checkFranchise"
                name="profile"
                value="checkFranchise"
              />
              <label class="form-check-label" for="checkFranchise">Franqueado</label>
            </div>
          </div>
        </ValidationProvider>
        <ValidationProvider>
          <div class="col-md-4 mb-3">
            <div class="form-check">
              <input
                :checked="checkSalesman"
                type="checkbox"
                class="form-check-input"
                id="checkSalesman"
                name="profile"
                value="checkSalesman"
              />
              <label class="form-check-label" for="checkSalesman">Vendedor</label>
            </div>
          </div>
        </ValidationProvider>
      </div>
      <actions
        :nameButton="buttom"
        :disabled="disabled"
        @closeModal="closeModal"
        @submitform="submitform"
      />
    </ValidationObserver>
  </modal>
</template>

<script>
import { mapActions } from "vuex";
export default {
  components: {
    modal: () => import("@/components/modal/modalDefault.vue"),
    actions: () => import("../acoes/botoes_rodape.vue"),
  },
  props: ["param"],
  data() {
    return {
      obj: {
        id: null,
        title: "",
        dateInitial: "",
        dateFinale: "",
        image: "",
        link: "",
        profile: -1,
        type: 0
      },
      disabled: false,
      fileName: "Nenhum arquivo foi Selecionado",
      labelImage: "Tamanho máximo de arquivo: 10mb",
      title: "Adicionar Novo Banner",
      buttom: "+Adicionar",
      rules: "required|image",
      disableCheckbox: false,
      checkFranchise: false,
      checkSalesman: false,
      bannerModal: true,
      bannerDashboard: false,
      edit: false
    };
  },
  mounted() {
    if (this.param != null) {
      this.edit = true;
      this.title = "Editar Banner";
      this.buttom = "Salvar";
      this.fileName = "Selecio um novo arquivo",
      this.disableCheckbox = true,
      this.rules = "";
      this.obj.title = this.param.notice.title;
      let dateInitial = this.param.notice.dateInitial.substring(0,10);
      this.obj.dateInitial = dateInitial;
      let dateFinale = this.param.notice.dateFinale.substring(0,10);
      this.obj.dateFinale = dateFinale;
      this.obj.link = this.param.notice.link;

      if (this.param.notice.profile == 0) {
        this.obj.profile = 0; // Se caiu aqui é para todos.
        this.checkFranchise = true;
        this.checkSalesman  = true;
      }else if (this.param.notice.profile == 2) {
        this.obj.profile = 2;
        this.checkFranchise = true;
      } else if (this.param.notice.profile == 3) {
        this.obj.profile = 3;
        this.checkSalesman = true;
      }
      if (this.param.notice.type == 0) {
        this.obj.type = 0;
        this.bannerModal = true;
        this.bannerDashboard = false;
      }else if (this.param.notice.type == 1) {
        this.obj.type = 1;
        this.bannerDashboard = true;
        this.bannerModal = false;
      }
    }
  },
  methods: {
    ...mapActions(["CreateNotice"]),
    ...mapActions(["EditNotice"]),
    async files(event) {
      const { valid } = await this.$refs.inputImg.validate(event);
      if (valid) {
        const { files } = event.target;
        const maxFile = 10000;
        if (files[0].size / 1024 > maxFile) {
          this.toast(
            "Tamanho maximo para arquivo é de 10mb!",
            this.$toast.error
          );
          $("#customFile").val("");
          return;
        }
        const url = URL.createObjectURL(files[0]);
        const img = new Image();
        img.onload = () => {
          if ($("#bannerDashboard")[0].checked) {
            if (img.width > 960 && img.height > 105) {
              this.toast( `Permitido apenas imagens com dimensão de até 960 x 105 pixels.`, this.$toast.error );
              $("#customFile").val("");
              return;
            } else if (img.width > 960) { 
              this.toast( `Permitido apenas imagens com dimensão de até 960 pixels de largura.`, this.$toast.error );
              $("#customFile").val("");
              return;
            } else if (img.height > 105) {
              this.toast( `Permitido apenas imagens com dimensão de até 105 pixels de altura.`, this.$toast.error );
              $("#customFile").val("");
              return;
            }
          }

          const reader = new FileReader();
          reader.onload = e => {
            console.log(e);
            this.obj.image = e.target.result;
          };

          reader.readAsDataURL(files[0]);
          this.fileName = files[0].name;
        };

        img.onerror = () => {
          this.toast(
            `Ops, algum problema na imagem, tente novamente`,
            this.$toast.error
          );
          $("#customFile").val("");
        };
        img.src = url;
      } else {
        $("#customFile").val("");
        this.fileName = "Nenhum arquivo foi Selecionado";
      }
    },
    send() {
      const { dateInitial, title, image } = this.form;
      // console.log(dateInitial);
      this.form.dateFinale = this.somaDate(
        dateInitial,
        Number(this.active - 2)
      );

      if (!dateInitial || !title || !this.form.dateFinale) {
        this.messageError("Verifique todos os campos");
        console.log(dateInitial, title, this.form.dateFinale, image);
        return false;
      }

      if (!image) {
        this.messageError("Para concluir, inclua o Banner");
        return false;
      }

      HTTP.post("services/app/Notice/Create", this.form, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.getterToken}`
        }
      })
        .then(() => {
          this.form = {};
          this.$refs.file.value = "";
          this.active = 0;
          $("#createModal").modal("hide");
          this.messageSucess("Recado criado com sucesso");
        })
        .catch(error => this.messageError(error.response.data.error.message));
    },
    somaDate(date, days) {
      let date1 = date.split("/");
      let day = date1[2] + "-" + date1[1] + "-" + date1[0];
      let dateCurrent = new Date(day);

      dateCurrent.setDate(dateCurrent.getDate());
      let myDate = new Date(day);

      myDate.setDate(myDate.getDate() + (days + 1));

      let ano = myDate.getFullYear();
      let dia = myDate.getDate();

      if (dia < 10) {
        dia = "0" + dia;
      }

      let mes = myDate.getMonth() + 1;

      if (mes < 10) {
        mes = "0" + mes;
      }

      return `${dia}/${mes}/${ano}`;
    },
    submitform() {
      var checkFranchise = $('#checkFranchise')[0].checked;
      var checkSalesman = $('#checkSalesman')[0].checked;
      if (checkFranchise == true && checkSalesman == true) {
        this.obj.profile = 0; // Se caiu aqui é para todos.
      }else if (checkFranchise == true) {
        this.obj.profile = 2;
      } else if (checkSalesman == true) {
        this.obj.profile = 3;
      }
      if ($("#bannerDashboard")[0].checked) { this.obj.type = 1 }
      this.$refs.formBanner.validate().then(success => {
        if (success) {
          this.disabled = true;
          if (this == undefined || this.param == undefined || this.param.notice.id == null) {
            this.CreateNotice(this.obj)
            .then(res => {
              this.closeModal();
              this.toast(res.msg, this.$toast.success);
            })
            .catch(err => {
              this.toast(err.msg, this.$toast.error);
              this.disabled = false;
            });
          } else {
            this.obj.id = this.param.notice.id;
            this.EditNotice(this.obj)
            .then(res => {
              this.closeModal();
              this.toast(res.msg, this.$toast.success);
            })
            .catch(err => {
              this.toast(err.msg, this.$toast.error);
              this.disabled = false;
            });
          }
        }
      });
    },
    closeModal() {
      this.$emit("closeModal");
      $("#modalCreateOrEdit").modal("hide");
    },
    changeLabelImageFileBannerDashboard(){
      this.files();
      $("#bannerModal")[0].checked = false;
      if ($("#bannerDashboard")[0].checked) {
        this.labelImage = "Tamanho máximo de arquivo: 10mb, largura 960 pixels e altura 105 pixels";
      } else {
        this.labelImage = "Tamanho máximo de arquivo: 10mb";
      }
    },
    changeLabelImageFileBannerModal(){
      this.files();
      $("#bannerDashboard")[0].checked = false;
      if ($("#bannerModal")[0].checked) {
        this.labelImage = "Tamanho máximo de arquivo: 10mb";       
      } else {
        this.labelImage = "Tamanho máximo de arquivo: 10mb, largura 960 pixels e altura 105 pixels";
      }
    }
  },
  computed: {
    getRegex() {
      return /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    }
  }
};
</script>
<style scoped src="@/assets/style/modal.css"></style>
<style scoped>
@media (max-width: 230px) {
  #myTabContent {
    max-width: 200;
    overflow-x: auto;
    min-width: 115px;
  }
}

#myTabContent {
  width: 100%;
}

.conteudo {
  min-height: 450px;
  padding-top: 12px;
}

.custom-file {
  border: 1px solid #98c4e6;
  border-radius: 6px !important;
  height: 40px !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-family: "Poppins";
  font-weight: 300 !important;
  font-size: 16px !important;
  max-width: 100% !important;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}

.custom-file-input {
  border: 1px solid #98c4e6;
  border-radius: 6px !important;
}
.custom-file-label {
  height: 40px !important;
}

.custom-file-label::after {
  background-color: #f38235 !important;
  color: #fff !important;
  height: 40px !important;
  width: 136px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.info {
  font-size: 10px !important;
}

.banner-type {
  margin-top: 10px !important;
}

.banner-label {
  margin-left: 35px !important;
}
</style>
